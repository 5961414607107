.search-result-container {
  margin: 10px 0px auto 0px;
  width: var(--group-wrapper-width);
}
.search-item-wrapper {
  display: flex;
}
.search-item-image {
  width: 150px;
  margin-right: 5px;
  margin-bottom: 15px;
}
.search-item-detail {
  flex: 1;
  margin-bottom: 15px;
}

.order-search-wrapper {
  margin-left: 10px;
}
.order-search-box {
  margin-left: 10px;
  display: flex;
}
.order-search-bar {
  height: 25px;
  border-radius: 1px;
}
.order-result-wrapper {
  margin-bottom: 15px;
}

.res-fivecol-wrapper {
  display: grid;
  grid-template-columns: max-content max-content max-content max-content max-content;
  margin-left: 15px;
  row-gap: 5px;
  column-gap: 10px;
  width: var(--group-wrapper-width);
}

.res-sixcol-wrapper {
  display: grid;
  grid-template-columns: max-content max-content max-content max-content max-content max-content;
  margin-left: 15px;
  row-gap: 5px;
  column-gap: 10px;
  width: var(--group-wrapper-width);
}
input[type="search"] {
  height: 25px;
}
@media (min-width: 700px) {
  .search-result-container {
    margin: 10px 0px auto 0px;
  }
  .search-item-image {
    width: 200px;
    margin-right: 20px;
  }
  .order-search-wrapper {
    margin-left: 25px;
  }
  .order-search-box {
    margin-left: 0px;
  }
}
@media (max-width: 699px) {
  input {
    border-radius: 1;
  }
  input[type="search"] {
    height: 26px;
    -webkit-appearance: none;
  }
}
