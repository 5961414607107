.otv-table-c11 {
  display: grid;
  grid-template-columns: max-content max-content max-content max-content max-content max-content max-content max-content max-content max-content max-content;
  grid-gap: 0px;
}
.otv-table-c9 {
  display: grid;
  grid-template-columns: max-content max-content max-content max-content max-content max-content max-content max-content max-content;
  grid-gap: 0px;
}
.otv-table-c6 {
  display: grid;
  grid-template-columns: max-content max-content max-content max-content max-content max-content;
  grid-gap: 0px;
}
.otv-item-ev {
  background-color: rgb(240, 240, 240);
  border: 1px solid #fff;
  padding: 5px;
  text-align: left;
}
.otv-item-od {
  background-color: rgb(220, 220, 220);
  border: 1px solid #fff;
  padding: 5px;
  text-align: left;
}
.otv-item-ev a {
  text-decoration: none;
}
.otv-item-od a {
  text-decoration: none;
}
.otv-hd {
  background-color: rgb(76, 121, 138);
  border: 1px solid #fff;
  padding: 5px;
  text-align: left;
}
.otv-hd a {
  text-decoration: none;
  color: white;
}
.otv-hdlink {
  background-color: rgb(192, 192, 192);
  border: 1px solid #fff;
  padding: 5px;
  text-align: left;
}
.otv-hdlink a {
  text-decoration: none;
  color: blue;
  font-weight: 500;
}

.otv-item-evr {
  background-color: rgb(240, 240, 240);
  border: 1px solid #fff;
  padding: 5px;
  text-align: right;
}
.otv-item-odr {
  background-color: rgb(220, 220, 220);
  border: 1px solid #fff;
  padding: 5px;
  text-align: right;
}
.otv-2col-form {
  display: grid;
  grid-template-columns: max-content max-content;
  gap: 1px 10px;
}
.left-30 {
  margin-left: 30px;
}
.small-btn3 {
  background-color: #5969b8;
  color: white;
  padding: 1px 8px 1px 8px;
  font-size: small;
}
