.pdf-container {
  width: var(--pdf-page-width);
  height: var(--pdf-page-height);
  position: relative;
  font-family: Arial, Helvetica, sans-serif;
}

.pdf-table {
  width: var(--pdf-table-percent);
  font-size: var(--pdf-fs-reg-table);
  margin-left: var(--pdf-margin-left);
  border-collapse: collapse;
}
.pdf-table-xs {
  width: var(--pdf-table-percent);
  font-size: var(--pdf-fs-large-table);
  margin-left: var(--pdf-margin-left);
  border-collapse: collapse;
}
.pdf-table th {
  background-color: #d0d0d0;
  border: 1px solid black;
  padding: 2px 0px 2px 0px;
}
.pdf-table td {
  border: 1px solid black;
  padding: 1px 4px 1px 4px;
}
.pdf-table-xs th {
  background-color: #d0d0d0;
  border: 1px solid black;
  padding: 2px 0px 2px 0px;
}
.pdf-table-xs td {
  border: 1px solid black;
  padding: 1px 6px 1px 6px;
}
.col2-table {
  display: grid;
  grid-template-columns: max-content max-content;
  row-gap: 5px;
  align-items: center;
}

.pdf-hdr-left {
  font-size: var(--pdf-fs-left-header);
  margin-left: var(--pdf-margin-left);
  margin-top: var(--pdf-margin-top);
}
.pdf-hdr-right {
  font-size: var(--pdf-fs-right-header);
  margin-left: var(--pdf-head-gap);
  margin-top: var(--pdf-margin-top);
}

.pdf-footer {
  position: absolute;
  bottom: var(--pdf-margin-bottom);
  left: var(--pdf-margin-left);
  right: 15px;
  font-size: var(--pdf-fs-footer);
}

.pdf-note {
  margin-top: 12px;
  margin-left: var(--pdf-margin-left);
  font-size: var(--pdf-fs-note);
}
.pdf-header2 {
  display: flex;
  margin-top: 8px;
  margin-bottom: 5px;
  margin-left: var(--pdf-margin-left);
  font-size: var(--pdf-fs-header2);
}
.pdf-shipping {
  margin-left: var(--pdf-shipto-left);
}
.pdf-left-comp {
  margin-left: var(--pdf-margin-left);
}
.pdf-left-header {
  margin-left: var(--pdf-margin-left);
  margin-bottom: 10px;
  font-weight: bold;
}
.pdf-btn {
  background-color: #5264b9;
  color: white;
  padding: 5px 10px;
  font-size: 13px;
}
.disabled-pdf-btn {
  background-color: grey;
  color: white;
  padding: 5px 10px;
  font-size: 13px;
}
.pdf-left5 {
  margin-left: 5px;
}
.pdf-l10t5 {
  margin-top: 5px;
  margin-left: 10px;
}
.pdf-send-wrapper {
  display: grid;
  row-gap: 5px;
  grid-template-columns: max-content;
  margin-left: 10px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: small;
}
.pdf-flex {
  display: flex;
  align-items: flex-start;
  margin-top: 0px;
}
.pdf-top-div {
  margin-top: 0px;
}
