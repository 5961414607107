.wl-threecol-table {
  display: grid;
  grid-template-columns: max-content max-content max-content;
  grid-gap: 0px;
}
.wl-4col-table {
  display: grid;
  grid-template-columns: max-content max-content max-content max-content;
  grid-gap: 0px;
}
.wl-5col-table {
  display: grid;
  grid-template-columns: max-content max-content max-content max-content max-content;
  grid-gap: 0px;
}
.wl-6col-table {
  display: grid;
  grid-template-columns: max-content max-content max-content max-content max-content max-content;
  grid-gap: 0px;
}
.wl-7col-table {
  display: grid;
  grid-template-columns: max-content max-content max-content max-content max-content max-content max-content;
  grid-gap: 0px;
}
.wl-grid-item-hd {
  background-color: lightblue;
  border: 0px solid #fff;
  padding: 5px;
  text-align: left;
}
.wl-grid-item-ev {
  background-color: rgb(242, 242, 242);
  border: 1px solid #fff;
  padding: 5px;
  text-align: left;
  vertical-align: center;
}
.wl-grid-item-od {
  background-color: rgb(230, 230, 230);
  border: 1px solid #fff;
  padding: 5px;
  text-align: left;
}
.wl-grid-item-hd a {
  text-decoration: none;
}
.wl-grid-item-ev a {
  text-decoration: none;
}
.wl-grid-item-od a {
  text-decoration: none;
}
