.sp-3col-wrapper {
  display: grid;
  grid-template-columns: max-content max-content max-content;
  width: var(--group-wrapper-width);
}
.sp-2col-wrapper {
  display: grid;
  grid-template-columns: max-content max-content;
  width: var(--group-wrapper-width);
  gap: 10px;
}
.sp-2col-table {
  display: grid;
  grid-template-columns: max-content max-content;
}

.sp-div a {
  text-decoration: none;
}

.sp-small-font-div {
  font-size: smaller;
}
.sp-form-value {
  padding-top: 4px;
  padding-bottom: 4px;
  padding-right: 10px;
  justify-self: start;
  display: flex;
  align-items: initial;
}
.sp-left-10 {
  margin-left: 10px;
}
.sp-left-10 a {
  text-decoration: none;
}
