.fivecol-table {
  display: grid;
  grid-template-columns: max-content max-content max-content max-content min-content;
  grid-gap: 2px;
}
.threecol-table {
  display: grid;
  grid-template-columns: max-content max-content max-content;
  grid-gap: 2px;
}
.twocol-table {
  display: grid;
  grid-template-columns: max-content max-content;
  grid-gap: 2px;
}
.grid-item0 {
  background-color: rgb(137, 163, 162);
  border: 1px solid #fff;
  padding: 5px;
  text-align: left;
}
.grid-item1 {
  background-color: rgb(52, 142, 184);
  border: 1px solid #fff;
  padding: 5px;
  text-align: left;
}
.grid-item0-ex {
  background-color: lightgrey;
  border: 1px solid #fff;
  padding: 5px;
  text-align: left;
  color: red;
}
.grid-item1-ex {
  background-color: darkgrey;
  border: 1px solid #fff;
  padding: 5px;
  text-align: left;
  color: red;
}
.grid-item2 {
  padding: 5px;
  text-align: left;
}
.grid-item {
  background-color: lightblue;
  border: 1px solid #fff;
  padding: 5px;
  text-align: left;
}
