.spprod-wrapper {
  display: grid;
  grid-template-columns: max-content;
}

@media (min-width: 700px) {
  .spprod-wrapper {
    grid-template-columns: max-content max-content max-content max-content;
  }
}
