.qty-box {
  display: flex;
  align-items: center;
}

.qty-plus {
  font-size: larger;
  font-weight: bold;
  padding-bottom: 2px;
  color: green;
  margin-left: 0px;
}
.qty-minus {
  font-size: larger;
  font-weight: bold;
  padding-bottom: 2px;
  color: red;
  margin-right: 2px;
}
.qty-minus-x {
  font-size: large;
  font-weight: bold;
  padding-bottom: 3px;
  color: red;
  margin-right: 2px;
}
.qty-plus a {
  text-decoration: none;
  color: green;
  cursor: pointer;
}
.qty-minus a {
  text-decoration: none;
  color: red;
  cursor: pointer;
}
.qty-minus-x a {
  text-decoration: none;
  color: red;
  cursor: pointer;
}
.qty-minus-img {
  margin-right: 3px;
  cursor: pointer;
}
.qty-plus-img {
  margin-left: 3px;
  cursor: pointer;
}
.qty-img {
  font-size: large;
  padding-bottom: 2px;
}
