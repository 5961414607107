.menu-wrapper {
  display: flex;
  width: var(--group-wrapper-width);
}

.menu-left-side {
  justify-content: space-between;
  margin-right: 10px;
}

.menu-right-side {
  padding-top: 5px;
  justify-content: flex-end;
  display: flex;
}
.menu-left-side a {
  text-decoration: none;
  font-weight: bolder;
  color: rgb(70, 70, 70);
}
.menu-right-side a {
  text-decoration: none;
  font-weight: bolder;
  color: rgb(70, 70, 70);
}

.menu-middle {
  flex: 1;
  margin: auto;
  margin-left: 30px;
}

/* For shopping cart */
.numberCircle {
  border-radius: 50%;
  width: 12px;
  height: 12px;
  padding: 1px;
  background: rgb(8, 8, 8);
  border: 2px solid #666;
  color: #fff;
  text-align: center;
  font: 10px Arial, sans-serif;
}

.menu-loc-icon {
  margin-right: 15px;
}
@media (min-width: 700px) {
  .menu-wrapper {
    align-content: stretch;
    padding-top: 10px;
  }
  .menu-left-side {
    margin-left: 0px;
  }
  .menu-right-side {
    flex: 1;
    padding-top: 5px;
  }
  .menu-wrapper > .menu-right-side > div {
    margin: 8px;
    font-size: 1em;
  }
}

.menu-mobile-home-img {
  margin-left: 10px;
}
