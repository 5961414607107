.ct-container {
  margin: 0px;
  width: var(--group-wrapper-width);
}
.ctinner-container {
  margin: 0px;
  width: var(--group-wrapper-width);
}
.ct-wrapper {
  margin: 0px;
}
.ct-title {
  margin-left: 10px;
  text-decoration: none;
}
.ct-single-line {
  margin-left: 10px;
}

/* grid container for 2 columns table */
.cttbl-container {
  display: grid;
  grid-template-columns: max-content max-content;
  padding: 10px;
  grid-row-gap: 1px;
}
/* table item left aligned */
.cttbl-item-left {
  border: 0px;
  text-align: left;
  padding: 2px 15px 2px 2px;
  font-family: Arial, Helvetica, sans-serif;
}
/* table item right aligned */
.cttbl-item-right {
  border: 0px;
  text-align: right;
  padding: 2px;
  font-family: Arial, Helvetica, sans-serif;
}

.cttbl-item-left0 {
  border: 0px;
  text-align: left;
  padding: 0px;
  font-family: Arial, Helvetica, sans-serif;
}

/* The Modal (background) */
.city-warn-modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.city-warn-modal-content {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
}

/* The Close Button */
.city-warn-close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.city-warn-close:hover,
.city-warn-close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.city-warn-button {
  visibility: hidden;
}

.contact-title {
  margin-top: 10px;
  margin-left: 10px;
}

.tbl-2col {
  display: grid;
  grid-template-columns: max-content max-content;
}