.generic-flex2 {
  display: flex;
  font-size: larger;
}

.bti-div {
  font-size: x-small;
}
.bti-div-small {
  font-size: small;
}
.bti-div-b10 {
  font-size: x-small;
  margin-bottom: 10px;
}
.bti-div2 {
  font-size: small;
}
.bti-cb {
  height: 10px;
  background-color: orange;
}
.atc-btn-style {
  background-color: orange;
  color: white;
  padding: 2px 8px;
  margin-top: 6px;
  font-size: smaller;
}
.top-5 {
  margin-top: 5px;
}
.bt-btn-style {
  background-color: #3a4dae;
  color: white;
  padding: 3px 10px;
  font-size: small;
}
.bt-red-btn-style {
  background-color: red;
  color: white;
  padding: 3px 10px;
  font-size: small;
}
.generic-2col {
  display: grid;
  grid-template-columns: max-content max-content;
}

.Select-control {
  table-layout: fixed;
}

.Select-input {
  overflow: hidden;
  max-width: 100%;
}
