.prod-wrapper {
  width: var(--group-wrapper-width);
}

.prod-detail-container {
  margin-bottom: 2px;
}

.prod-detail-image {
  padding: 2px;
  margin-bottom: 10px;
  justify-content: flex-end;
}

.prod-detail-info {
  padding: 5px;
  margin-bottom: 10px;
  flex: 1;
  overflow-wrap: normal;
}
.prod-tbl-container2 {
  display: grid;
  grid-template-columns: max-content max-content;
  color: #606060;
}
.prod-tbl-item-left {
  border: 0px;
  text-align: left;
  padding: 2px 5px 2px 2px;
  font-family: Arial, Helvetica, sans-serif;
}
.modal-image {
  width: var(--modal-image-width);
}
@media (min-width: 700px) {
  .prod-detail-container {
    display: flex;
    justify-content: space-between;
  }
  .prod-detail-image {
    padding: 10px 10px 10px 0px;
  }
  .prod-detail-info {
    padding: 20px;
    width: var(--prod-detail-width);
  }
}
