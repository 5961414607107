.rtn-table {
  font-size: smaller;
  border-collapse: collapse;
}
.rtn-table th {
  background-color: #ee9696;
  border: 1px solid black;
  padding: 2px 0px 2px 0px;
}
.rtn-table td {
  border: 1px solid black;
  padding: 2px 4px 2px 4px;
  text-decoration: none;
}
.rtn-col2-table {
  display: grid;
  grid-template-columns: max-content max-content;
  grid-gap: 2px;
}
.rtn-flex {
  display: flex;
  align-items: center;
  margin-top: 0px;
}
.left5 {
  margin-left: 5px;
}
.rtn-header {
  font-weight: bold;
  color: #ee9696;
  margin-bottom: 10px;
}
