.os-table-c5 {
  display: grid;
  grid-template-columns: max-content max-content max-content max-content max-content;
  grid-gap: 0px;
}
.os-table-c4 {
  display: grid;
  grid-template-columns: max-content max-content max-content max-content;
  grid-gap: 0px;
}
.os-table-c3 {
  display: grid;
  grid-template-columns: max-content max-content max-content;
  grid-gap: 0px;
}
.grid-item-hd {
  background-color: rgb(85, 189, 224);
  border: 0px solid #fff;
  padding: 5px;
  text-align: left;
}
.grid-item-ev {
  background-color: rgb(240, 240, 240);
  border: 1px solid #fff;
  padding: 5px;
  text-align: left;
}
.grid-item-od {
  background-color: rgb(220, 220, 220);
  border: 1px solid #fff;
  padding: 5px;
  text-align: left;
}

.grid-item-ev a {
  text-decoration: none;
}
.grid-item-od a {
  text-decoration: none;
}
.grid-item-hd a {
  text-decoration: none;
}

.delivered-label {
  background-color: blue;
  font-weight: bold;
  color: white;
}
