.cat-wrapper {
  display: flex;
  border-bottom: 1px solid #909090;
  margin-bottom: 15px;
  width: var(--group-wrapper-width);
}

.cat-left-side {
  justify-content: space-between;
  display: flex;
}

.cat-wrapper > .cat-left-side > div {
  margin: 8px 8px 8px 0px;
  font-size: 1em;
}

.cat-link {
  height: 24px;
  border-bottom: 1px solid transparent;
  transition: border-bottom 0.5s;
  padding-right: 2px;
}
.cat-link a {
  color: black;
  text-decoration: none;
  transition: color 0.5s;
  text-transform: uppercase;
  font-weight: 500;
}

.cat-link-special {
  height: 24px;
  border-bottom: 1px solid transparent;
  transition: border-bottom 0.5s;
  padding-right: 10px;
}

.cat-link-special a {
  color: #8a8a8a;
  text-decoration: none;
  transition: color 0.5s;
  text-transform: uppercase;
  font-weight: 500;
}

.cat-link:hover {
  border-bottom: 1px solid black;
}

.cat-link a:hover {
  color: black;
}

@media (min-width: 700px) {
  .cat-link {
    padding-right: 10px;
  }
}
