.top7r20 {
  margin-top: 7px;
  margin-right: 20px;
}

.pmt-twocol-table {
  display: grid;
  grid-template-columns: max-content max-content;
}

.fs-verify-container {
  padding-right: 40px;
}
@media (min-width: 700px) {
  .fs-verify-container {
    padding-right: 70px;
  }
}
