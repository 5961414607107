.edit-cat-wrapper {
  display: grid;
  align-items: flex-start;
  grid-template-columns: max-content max-content;
}

@media (min-width: 700px) {
  .edit-cat-wrapper {
    display: grid;
    align-items: flex-start;
    grid-template-columns: max-content max-content max-content;
  }
}
