.form-container {
  margin: 10px 0px 10px 0px;
  width: var(--group-wrapper-width);
}

.form-wrapper {
  display: grid;
  grid-template-columns: max-content max-content;
  width: var(--group-wrapper-width);
}

.form-item-label {
  padding-right: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
  text-align: left;
}
.form-item-label a {
  text-decoration: none;
}
.form-item-value {
  padding-top: 4px;
  padding-bottom: 4px;
  padding-right: 10px;
  justify-self: start;
}
.form-item-value a {
  text-decoration: none;
}

.form-item-label0 {
  padding-right: 10px;
  text-align: left;
}
.form-item-label0 a {
  text-decoration: none;
}
.form-item-value0 {
  padding-right: 10px;
  justify-self: start;
}
.form-item-value0 a {
  text-decoration: none;
}

.form-item-value-flex {
  padding-top: 4px;
  padding-bottom: 4px;
  padding-right: 10px;
  justify-self: start;
  display: flex;
  align-items: center;
}
.form-item-value-flex a {
  text-decoration: none;
}

.form-wrapper-with-border {
  display: grid;
  grid-template-columns: max-content max-content;
  border: 1px solid black;
  padding: 5px 15px 5px 15px;
}

.top-10 {
  margin-top: 10px;
}

.small-form-wrapper {
  display: grid;
  grid-template-columns: max-content max-content;
  align-items: center;
}

.hand:hover {
  cursor: pointer;
}

.flex-b10 {
  display: flex;
  margin-bottom: 10px;
}

.fourcol-wrapper2 {
  display: grid;
  grid-template-columns: max-content max-content max-content max-content;
  row-gap: 5px;
}
.form-item-label-t10 {
  padding-right: 10px;
  padding-top: 14px;
  padding-bottom: 4px;
  text-align: left;
}
.form-item-value-t10 {
  padding-top: 14px;
  padding-bottom: 4px;
  padding-right: 10px;
  justify-self: start;
}

.fs-container {
  margin-top: 10px;
  max-width: 500px;
}
.generic-form-container {
  display: grid;
  grid-template-columns: max-content max-content;
  row-gap: 5px;
}
