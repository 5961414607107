.prod-thumbnail-wrapper {
  display: grid;
  grid-template-columns: max-content max-content max-content max-content;
  row-gap: 5px;
}
.grid-wrapper-top {
  display: grid;
  align-items: flex-start;
  grid-template-columns: max-content;
}
#soldOutImage {
  opacity: 0.4;
  filter: alpha(opacity=40);
}

.brightness {
  background-color: white;
  display: inline-block;
  margin-right: 5px;
}
.brightness img:hover {
  opacity: 0.5;
}
.brightness-selected {
  background-color: white;
  display: inline-block;
  margin-right: 5px;
  border: 1px solid red;
}
.selected {
  border: 1px solid red;
}
.varname-box {
  border: 1px solid darkgray;
  margin-right: 5px;
  padding: 3px;
  cursor: pointer;
  background-color: rgb(246, 250, 252);
  font-family: Arial, Helvetica, sans-serif;
  font-size: small;
  font-weight: 400;
}
.varname-box-selected {
  border: 1px solid red;
  margin-right: 5px;
  padding: 3px;
  cursor: pointer;
  background-color: beige;
  font-family: Arial, Helvetica, sans-serif;
  font-size: small;
  font-weight: 550;
}
.varname-box-nfs {
  border: 1px dotted darkgray;
  margin-right: 5px;
  padding: 3px;
  cursor: pointer;
  color: gray;
  font-family: Arial, Helvetica, sans-serif;
  font-size: small;
  font-weight: 400;
  background: linear-gradient(
    to top left,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) calc(50% - 0.8px),
    rgba(0, 0, 0, 1) 50%,
    rgba(0, 0, 0, 0) calc(50% + 0.8px),
    rgba(0, 0, 0, 0) 100%
  );
}
.varname-box-nfs-selected {
  border: 0.5px solid red;
  margin-right: 5px;
  padding: 3px;
  cursor: pointer;
  color: gray;
  font-family: Arial, Helvetica, sans-serif;
  font-size: small;
  background: linear-gradient(
    to top left,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) calc(50% - 0.8px),
    rgba(0, 0, 0, 1) 50%,
    rgba(0, 0, 0, 0) calc(50% + 0.8px),
    rgba(0, 0, 0, 0) 100%
  );
}

.varnamef-box {
  border: 1px solid darkgray;
  margin-right: 5px;
  padding: 3px;
  cursor: pointer;
  background-color: rgb(246, 250, 252);
  font-family: Arial, Helvetica, sans-serif;
  font-size: small;
  font-weight: 400;
  display: flex;
  align-items: center;
}
.varnamef-box-selected {
  border: 1px solid red;
  margin-right: 5px;
  padding: 3px;
  cursor: pointer;
  background-color: rgb(246, 250, 252);
  font-family: Arial, Helvetica, sans-serif;
  font-size: small;
  font-weight: 550;
  display: flex;
  align-items: center;
}
.varnamef-box-nfs {
  border: 1px dotted darkgray;
  margin-right: 5px;
  padding: 3px;
  cursor: pointer;
  color: gray;
  font-family: Arial, Helvetica, sans-serif;
  font-size: small;
  font-weight: 400;
  display: flex;
  align-items: center;
  background: linear-gradient(
    to top left,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) calc(50% - 0.8px),
    rgba(0, 0, 0, 1) 50%,
    rgba(0, 0, 0, 0) calc(50% + 0.8px),
    rgba(0, 0, 0, 0) 100%
  );
}
.varnamef-box-nfs-selected {
  border: 0.5px solid red;
  margin-right: 5px;
  padding: 3px;
  cursor: pointer;
  color: gray;
  font-family: Arial, Helvetica, sans-serif;
  font-size: small;
  font-weight: 540;
  display: flex;
  align-items: center;
  background: linear-gradient(
    to top left,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) calc(50% - 0.8px),
    rgba(0, 0, 0, 1) 50%,
    rgba(0, 0, 0, 0) calc(50% + 0.8px),
    rgba(0, 0, 0, 0) 100%
  );
}

.hr-style {
  width: 300px;
  border: 0.8px solid darkgray;
}
.small-btn2 {
  background-color: #5969b8;
  color: white;
  padding: 1px 5px;
  font-size: small;
}
.small-btn2-disabled {
  background-color: grey;
  color: white;
  padding: 1px 5px;
  font-size: small;
}
@media (min-width: 700px) {
  .prod-thumbnail-wrapper {
    grid-template-columns: max-content max-content max-content max-content max-content;
  }
  .grid-wrapper-top {
    grid-template-columns: max-content max-content max-content;
  }
}
