.right-10 {
  margin-right: 10px;
}
.left-15 {
  margin-left: 15px;
}
.bottom-10 {
  margin-bottom: 10px;
}
.generic-flex-top {
  display: flex;
  align-items: center;
}
.combo-image-wrapper {
  display: grid;
  grid-template-columns: max-content max-content max-content max-content;
}

.width400 {
  width: 400px;
}
@media (min-width: 700px) {
  .combo-image-wrapper {
    display: grid;
    grid-template-columns: max-content max-content max-content max-content max-content max-content max-content max-content;
  }
}
