[data-reach-dialog-content] {
  width: 80vw;
  margin: 2vh auto;
  background: white;
  padding: 1rem;
  outline: none;
}

@media (min-width: 700px) {
  [data-reach-dialog-content] {
    width: 50vw;
    margin: 10vh auto;
    background: white;
    padding: 2rem;
    outline: none;
  }
}
