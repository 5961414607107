.generic-wrapper {
  width: var(--group-wrapper-width);
  margin: 5px 0px 5px 0px;
}

.generic-flex {
  display: flex;
}
.info-wrapper {
  width: var(--info-wrapper-width);
}
.generic-item {
  padding: 0px 10px 20px 10px;
  vertical-align: top;
}
.span-nodec a {
  text-decoration: none;
}
@media (min-width: 700px) {
  .generic-wrapper {
    margin: 15px 0px 15px 0px;
  }
}
.c4-table-left5 {
  display: grid;
  grid-template-columns: max-content max-content max-content max-content;
  grid-gap: 0px;
  margin-left: 5px;
}
.flex-wrapper {
  display: flex;
  align-items: center;
}
.flex-wrapper a {
  text-decoration: none;
  color: blue;
}
.flex-wrapper-10 {
  display: flex;
  align-items: center;
  margin-left: 10px;
}
.flex-wrapper-b10 {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.db-title {
  padding-bottom: 15px;
}
.db-title a {
  text-decoration: none;
}
.blue-title {
  font-weight: bold;
  color: orangered;
}

.invisible-btn {
  display: none;
}
.top30w400 {
  margin-top: 30px;
  width: 400px;
}
.width-20 {
  width: 20px;
  margin-left: 20px;
}
.left-25 {
  margin-left: 25px;
}
.left-8 {
  margin-left: 8px;
}
.width-10 {
  width: 10px;
}
.width-30 {
  width: 30px;
}
.width-40 {
  width: 40px;
}
.threecol-wrapper {
  display: grid;
  grid-template-columns: max-content max-content max-content;
}
.fourcol-wrapper {
  display: grid;
  grid-template-columns: max-content max-content max-content max-content;
}
.fivecol-form {
  display: grid;
  grid-template-columns: max-content max-content max-content max-content max-content;
}
.twocol-table {
  display: grid;
  grid-template-columns: max-content max-content;
  grid-gap: 2px;
}
.twocol-wrapper {
  width: var(--group-wrapper-width);
  margin: 5px 0px 5px 0px;
  display: grid;
  grid-template-columns: max-content max-content;
}

.sixcol-wrapper {
  width: var(--group-wrapper-width);
  margin: 5px 0px 5px 0px;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}
.tencol-wrapper {
  width: var(--group-wrapper-width);
  margin: 5px 0px 5px 0px;
  display: grid;
  grid-template-columns: repeat(10, 1fr);
}
.tencol-wrapper a {
  text-decoration: none;
  color: #3066cc;
}
.left-10 {
  margin-left: 10px;
  vertical-align: top;
}
.left-5 {
  margin-left: 5px;
  vertical-align: top;
}
.left-20 {
  margin-left: 20px;
}
.right-10 {
  margin-right: 10px;
}
.right-20 {
  margin-right: 20px;
}
.bottom-10 {
  margin-bottom: 10px;
}
.bottom-15 {
  margin-bottom: 15px;
}
.flex-wrapper-top {
  display: flex;
  align-items: flex-start;
}

.left-40 {
  margin-left: 40px;
}
.left-60 {
  margin-left: 60px;
}
.fourcol-form {
  margin: 5px 0px 5px 0px;
  display: grid;
  grid-template-columns: max-content max-content max-content max-content;
}
.flex-wrapper-full {
  display: flex;
  width: var(--group-wrapper-width);
}
.fill-right {
  flex: 1;
}
.flex-wrapper-top20 {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.sixcol-wrapper-top20 {
  margin-top: 20px 0px 5px 0px;
  display: grid;
  grid-template-columns: max-content max-content max-content max-content max-content max-content;
}
.fourcol-wrapper-top20 {
  margin-top: 20px 0px 5px 0px;
  display: grid;
  grid-template-columns: max-content max-content max-content max-content;
}
.div-width800 {
  width: 800px;
}

.flex-center {
  display: flex;
  align-items: center;
  padding-right: 2px;
}

.hr-240 {
  width: 240px;
  height: 1.2px;
  border-width: 0px;
  color: gray;
  background-color: gray;
}
.hr-300 {
  width: 300px;
  height: 1.2px;
  border-width: 0px;
  color: gray;
  background-color: gray;
}
.nodec-div a {
  text-decoration: none;
}
.form-wrapper-l20 {
  display: grid;
  grid-template-columns: max-content max-content;
  margin-left: 20px;
}
.top10b8 {
  margin-top: 10px;
  margin-bottom: 8px;
}

.fourcol-wrapper-g10 {
  margin: 5px 0px 5px 0px;
  column-gap: 10px;
  row-gap: 5px;
  display: grid;
  grid-template-columns: max-content max-content max-content max-content;
}

.custom-file-upload {
  cursor: pointer;
  background-color: orangered;
  color: white;
  padding: 5px 20px;
  font-size: 13px;
  display: inline-block;
  line-height: 2em;
  padding: 0 0.88em;
  border: 2px solid rgb(192, 64, 13);
  box-shadow: inset 0 0 0.1em rgb(238, 97, 3),
    0.2em 0.2em 0.2em rgba(224, 95, 21, 0.3);
}
.custom-file-upload:hover {
  background-image: linear-gradient(to bottom, orange, red);
}
.generic-flex-top10 {
  display: flex;
  margin-top: 10px;
}
.generic-flex-b15 {
  display: flex;
  margin-bottom: 15px;
}
.generic-flex-b8 {
  display: flex;
  margin-bottom: 8px;
}

.subtitle-container {
  margin-bottom: 10px;
  font-size: larger;
  display: flex;
}
.hr-100 {
  width: 100px;
  height: 1.2px;
  border-width: 0px;
  color: gray;
  background-color: gray;
}
.div-nodec a {
  text-decoration: none;
}
.bottom-flex {
  display: flex;
  align-items: flex-end;
}

.flex-wrapper-tb10 {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}
.green-mbtn {
  background-color: rgb(39, 100, 39);
  color: white;
  padding: 4px 10px;
  font-size: 13px;
}
.disabled-green-mbtn {
  background-color: grey;
  color: white;
  padding: 4px 10px;
  font-size: 13px;
}
.left-5-nodec {
  margin-left: 5px;
  font-size: large;
}
.left-5-nodec a {
  text-decoration: none;
}
.left-5-bold {
  margin-left: 5px;
  font-size: large;
  font-weight: bold;
}
.generic-flex-top5 {
  display: flex;
  margin-top: 5px;
}
.tbl-container3 {
  display: grid;
  grid-template-columns: max-content max-content;
  row-gap: 5px;
  column-gap: 10px;
  padding: 10px;
}
.choice-label:hover {
  background-color: rgb(111, 143, 202);
  cursor: pointer;
  color: white;
}
.red-small-btn {
  background-color: red;
  color: white;
  padding: 2px 10px;
  font-size: 13px;
}
.green-small-btn {
  background-color: green;
  color: white;
  padding: 2px 10px;
  font-size: 13px;
}
.orange-small-btn {
  background-color: orange;
  color: white;
  padding: 2px 10px;
  font-size: 13px;
}
.blue-small-btn {
  background-color: blue;
  color: white;
  padding: 2px 10px;
  font-size: 13px;
}
.disabled-blue-small-btn {
  background-color: grey;
  color: white;
  padding: 2px 10px;
  font-size: 13px;
}
.crossed {
  background: linear-gradient(
      to top left,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) calc(50% - 0.8px),
      rgba(0, 0, 0, 1) 50%,
      rgba(0, 0, 0, 0) calc(50% + 0.8px),
      rgba(0, 0, 0, 0) 100%
    ),
    linear-gradient(
      to top right,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) calc(50% - 0.8px),
      rgba(0, 0, 0, 1) 50%,
      rgba(0, 0, 0, 0) calc(50% + 0.8px),
      rgba(0, 0, 0, 0) 100%
    );
}
.single-col {
  display: grid;
  grid-template-columns: max-content;
  row-gap: 5px;
}
#disabledImage {
  opacity: 0.4;
  filter: alpha(opacity=40);
}
.green-link-label {
  border: 1px solid rgb(27, 90, 66);
  background-color: rgb(27, 90, 66);
  color: white;
  cursor: pointer;
  border-radius: 15px;
  padding: 1px 20px 3px 20px;
}
.green-link-label:hover {
  border: 1px solid rgb(54, 180, 132);
  background-color: rgb(54, 180, 132);
  color: red;
}
.lease-group-container {
  display: grid;
  grid-template-columns: max-content max-content;
  grid-gap: 8px 6px;
  margin-top: 10px;
  margin-left: 10px;
  width: var(--group-wrapper-width);
}
@media (min-width: 700px) {
  .lease-group-container {
    grid-template-columns: max-content 700px;
  }
}

