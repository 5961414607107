.App {
  text-align: center;
}

:root {
  --top-container-width: 1200px;
  --prodcat-column-count: 3;
  --info-wrapper-width: 750px;
  --info-wrapper-margin-left: 25px;
  --group-wrapper-width: 1170px;
  --slider-pre-color: gold;
  --slider-next-color: gold;
  --thumbnail-item-width: 150px;
  --thumbnail-column-count: 3;
  --prod-detail-width: 350px;
  --hammenu-display-mode: block;
  --faq-answer-width: 1150px;
  --modal-image-width: 500px;

  --pdf-page-width: 595px;
  --pdf-page-height: 842px;
  --pdf-margin-left: 22px;
  --pdf-margin-bottom: 65px;
  --pdf-margin-top: 20px;
  --pdf-head-gap: 160px;
  --pdf-shipto-left: 120px;
  --pdf-table-percent: 92%;

  --pdf-fs-reg-table: smaller;
  --pdf-fs-large-table: x-small;
  --pdf-fs-left-header: x-small;
  --pdf-fs-right-header: smaller;
  --pdf-fs-header2: smaller;
  --pdf-fs-note: smaller;
  --pdf-fs-footer: x-small;
}

.top-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;
  width: var(--top-container-width);
}

.top-wrapper {
  display: block;
  margin-left: 10px;
  margin-right: 10px;
  width: var(--group-wrapper-width);
}

.slick-dots li.slick-active button:before {
  color: blue !important;
}
.slick-prev {
  left: 3% !important;
  z-index: 1;
}
.slick-next {
  right: 3% !important;
  z-index: 1;
}
.slick-prev:before {
  color: red;
}
.slick-next:before {
  color: green;
}

.btn-style {
  background-color: #3a4dae;
  color: white;
  padding: 5px 20px;
  font-size: 13px;
}
.green-btn-style {
  background-color: #3aae57;
  color: white;
  padding: 5px 20px;
  font-size: 13px;
}
.orange-btn-style {
  background-color: orange;
  color: white;
  padding: 5px 20px;
  font-size: 13px;
}
.red-btn-style {
  background-color: red;
  color: white;
  padding: 5px 20px;
  font-size: 13px;
}
.disabled-btn-style {
  background-color: grey;
  color: white;
  padding: 5px 20px;
  font-size: 13px;
}
.lightgreen-btn {
  background-color: rgb(12, 158, 221);
  color: white;
  padding: 4px 10px;
  font-size: 13px;
}
.disabled-lightgreen-btn {
  background-color: grey;
  color: white;
  padding: 4px 10px;
  font-size: 13px;
}
.grey-btn-style {
  background-color: lightgray;
  color: black;
  padding: 5px 20px;
  font-size: 13px;
}
.redCircle {
  border-radius: 50%;
  width: 6px;
  height: 6px;
  margin: 1px 4px 1px 1px;
  background: red;
}

.greenCircle {
  border-radius: 50%;
  width: 6px;
  height: 6px;
  margin: 1px 4px 1px 1px;
  background: green;
}
.orangeCircle {
  border-radius: 50%;
  width: 6px;
  height: 6px;
  margin: 1px 4px 1px 1px;
  background: orange;
}
.blueCircle {
  border-radius: 50%;
  width: 6px;
  height: 6px;
  margin: 1px 4px 1px 1px;
  background: blue;
}
.small-btn {
  background-color: #3a4dae;
  color: white;
  padding: 3px 5px;
}
.red-small-btn {
  background-color: red;
  color: white;
  padding: 3px 5px;
}
.disabled-small-btn {
  background-color: grey;
  color: white;
  padding: 3px 5px;
}
.tiny-btn {
  background-color: #3a4dae;
  color: white;
  padding: 0px 0px;
  font-size: x-small;
}
.narrow-btn {
  background-color: #3a4dae;
  color: white;
  padding: 5px 8px;
  font-size: 13px;
}
.disabled-narrow-btn {
  background-color: grey;
  color: white;
  padding: 5px 8px;
  font-size: 13px;
}

.title-flex {
  display: flex;
  gap: 0px 100px;
  margin-bottom: 15px;
  align-items: center;
}

.title-flex a {
  text-decoration: none;
  color: darkgreen;
}
.green-small-btn {
  background-color: #3aae57;
  color: white;
  padding: 3px 7px;
  font-size: 12px;
}
.disabled-green-small-btn {
  background-color: grey;
  color: white;
  padding: 3px 7px;
  font-size: 11px;
}
@media (min-width: 700px) {
  .top-wrapper {
    margin-left: auto;
    margin-right: auto;
  }
}
