.blue-btn {
  background-color: #3a4dae;
  color: white;
  padding: 2px 10px;
  font-size: 13px;
}
.disabled-btn {
  background-color: grey;
  color: white;
  padding: 2px 10px;
  font-size: 13px;
}
.red-btn {
  background-color: red;
  color: white;
  padding: 2px 10px;
  font-size: 13px;
}
.green-btn {
  background-color: #3aae57;
  color: white;
  padding: 5px 20px;
  font-size: 13px;
}

.faq-table {
  margin: 5px 0px 5px 0px;
  display: grid;
  grid-template-columns: max-content max-content;
  grid-gap: 1px;
}

.grid-item-q {
  background-color: white;
  border: 1px solid #fff;
  padding: 5px;
  text-align: left;
  width: var(--faq-answer-width);
}
.grid-item-a0 {
  /*background-color: rgb(236, 229, 229);*/
  border: 1px solid #fff;
  padding: 5px;
  text-align: left;
  width: var(--faq-answer-width);
}
.grid-item-a1 {
  /*background-color: rgb(195, 194, 195);*/
  border: 1px solid #fff;
  padding: 5px;
  text-align: left;
  width: var(--faq-answer-width);
}
.faq-number {
  padding-top: 5px;
  padding-right: 5px;
}
.img-text-wrap0 {
  float: left;
  margin: 0px 10px 10px 0px;
}
.img-text-wrap1 {
  float: right;
  margin: 0px 10px 10px 0px;
}
