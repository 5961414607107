.homelink-box {
  border: 1px solid lightblue;
  background: -webkit-linear-gradient(lightblue, blue);
  display: flex;
  border-radius: 3px;
  width: 105px;
  height: 70px;
  margin: 10px;
}

.homelink-item {
  margin: auto;
}

.homelink-item a {
  text-decoration: none;
  color: white;
  font-weight: bolder;
}

.home-container {
  display: flex;
  justify-content: left;
}

.homelink-box-blue {
  border: 1px solid lightblue;
  background: -webkit-linear-gradient(lightblue, blue);
  display: flex;
  border-radius: 3px;
  width: 80px;
  height: 60px;
  margin: 10px;
}

.homelink-box-grey {
  border: 1px solid lightgrey;
  background: -webkit-linear-gradient(lightgrey, darkgrey);
  display: flex;
  border-radius: 3px;
  width: 80px;
  height: 60px;
  margin: 10px;
}

.homebox-wrapper {
  width: var(--group-wrapper-width);
  margin: 5px 0px 5px 0px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.fivecol-wrapper {
  display: grid;
  grid-template-columns: max-content max-content max-content max-content max-content;
  margin-left: 15px;
  width: var(--group-wrapper-width);
}
.col4-wrapper {
  display: grid;
  grid-template-columns: max-content max-content max-content max-content;
  margin-left: 15px;
  width: var(--group-wrapper-width);
}
.home-sixcol-wrapper {
  display: grid;
  grid-template-columns: max-content max-content max-content max-content max-content max-content;
  margin-left: 15px;
  width: var(--group-wrapper-width);
}
.home-7col-wrapper {
  display: grid;
  grid-template-columns: max-content max-content max-content max-content max-content max-content max-content;
  margin-left: 15px;
  width: var(--group-wrapper-width);
}
.home-item {
  margin-left: 10px;
  margin-top: auto;
  margin-bottom: auto;
  vertical-align: middle;
  font-size: small;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}
.home-item a {
  text-decoration: none;
  color: black;
}
.homeitems-wrapper {
  width: var(--group-wrapper-width);
  margin: 5px 0px 20px 0px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 20px;
}
.left20-b25 {
  margin-left: 20px;
  margin-bottom: 25px;
}
.bottom25 {
  margin-bottom: 25px;
}
.home-log-title {
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  font-size: small;
}
.home-log-item {
  font-family: "Courier New", Courier, monospace;
  font-size: smaller;
}
@media (min-width: 700px) {
  .homebox-wrapper {
    grid-template-columns: repeat(5, 1fr);
  }
  .homeitems-wrapper {
    margin: 5px 20px 20px 20px;
    grid-template-columns: repeat(5, 1fr);
  }
  .homelink-box {
    border-radius: 6px;
    width: 130px;
    height: 90px;
    margin: 30px;
  }
  .homelink-box-blue {
    border-radius: 6px;
    width: 130px;
    height: 90px;
    margin: 30px;
  }
  .homelink-box-grey {
    border-radius: 6px;
    width: 130px;
    height: 90px;
    margin: 30px;
  }
}
